import Axios, {AxiosError} from 'axios';
import router from 'next/router';
import {
	clearAuthenticationToken,
	getAuthorizationHeader,
	getCachedAuthenticationToken
} from '../modules/authentication/provider/token';
import {routeToError, routeToLoginImperatively} from 'modules/navigation/utils';

export const http = Axios.create({
	baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
	timeout: 30000
});

// automatically adds authorization header
http.interceptors.request.use(config => {
	if (process.env.NODE_ENV === 'test') {
		return config;
	}
	const lang = router.locale;
	const token = getCachedAuthenticationToken();
	if (lang) {
		config.headers['Accept-Language'] = lang;
	}
	if (token) {
		config.headers.Authorization = getAuthorizationHeader();
	}
	return config;
});

// intercept authentication errors
http.interceptors.response.use(
	response => response,
	(error: AxiosError) => {
		console.error('Api Request error : ', error);
		return Promise.reject(error);
	}
);

/**
 * This fetcher is for useSWR caching system of next js.
 * Use ONLY for hydrating data.
 *
 * Ensure
 *
 * @param url the url to fetch
 * @returns The promise.
 */
export const httpFetcher = url =>
	http
		.get(url)
		.then(res => res.data)
		.catch(error => {
			// TODO: in some case : 403 must be handled in other ways.
			if (error?.response && [401, 403].includes(error?.response?.status)) {
				console.error(
					'The hydrating call has been rejected : returns to login. Status : ',
					error?.response?.status
				);
				clearAuthenticationToken();
				return routeToLoginImperatively();
			}
			if (error?.response && [403].includes(error?.response?.status)) {
				console.error(
					'The hydrating 403 call has been rejected : returns to login. Status : ',
					error?.response?.status
				);
				return routeToError();
			}
			// reissue error for handling by useSWR.
			return Promise.reject(error);
		});
export const httpExcelFetcher = url =>
	http
		.get(url, {responseType: 'blob'})
		.then(res => res.data)
		.catch(error => {
			// TODO: in some case : 403 must be handled in other ways.
			if (error?.response && [401, 403].includes(error?.response?.status)) {
				console.error(
					'The hydrating call has been rejected : returns to login. Status : ',
					error?.response?.status
				);
				clearAuthenticationToken();
				return routeToLoginImperatively();
			}
			if (error?.response && [403].includes(error?.response?.status)) {
				console.error(
					'The hydrating 403 call has been rejected : returns to login. Status : ',
					error?.response?.status
				);
				return routeToError();
			}
			// reissue error for handling by useSWR.
			return Promise.reject(error);
		});
